import { Container, Hidden, Typography } from '@material-ui/core'
import React from 'react'
import { currentData } from '../../../data'
import { Social } from './Social'
export function CVFooter() {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: currentData.palette.primary,
      }}
    >
      <div
        style={{
          padding: 16,
          borderRadius: 8,
          color: '#FFF',
        }}
      >
        <Typography align={'center'} variant={'h4'}>
          {currentData.firstName} {currentData.secondName}
        </Typography>
        <br />

        <Typography align={'center'} variant={'body1'}>
          {currentData.email}
        </Typography>
        <Typography variant={'body1'} align={'center'}>
          {currentData.city} | {currentData.county}
        </Typography>
        <Typography align={'center'} variant={'body1'}>
          {currentData.phoneNumber}
        </Typography>
        <Typography align={'center'} variant={'body1'}>
          2020
        </Typography>
      </div>
    </div>
  )
}
