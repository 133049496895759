import React, { FunctionComponent } from 'react'
import Care from '../assets/care.svg'
import Canoe from '../assets/canoe.svg'
import Inventory from '../assets/inventory.svg'
import Lecture from '../assets/lecture.svg'
import Mask from '../assets/mask.svg'
import SpeechBubble from '../assets/speech-bubble.svg'

export type ListedIcon =
  | 'canoe'
  | 'care'
  | 'inventory'
  | 'lecture'
  | 'mask'
  | 'speech-bubble'

export interface GetIconProps {
  type: ListedIcon
  style: React.CSSProperties
  className: string
}
export const GetIcon: FunctionComponent<GetIconProps> = ({
  type,
  style,
  className,
}: GetIconProps) => {
  switch (type) {
    case 'canoe':
      return <Canoe style={style} className={className} />
    case 'care':
      return <Care style={style} className={className} />
    case 'inventory':
      return <Inventory style={style} className={className} />
    case 'lecture':
      return <Lecture style={style} className={className} />
    case 'mask':
      return <Mask style={style} className={className} />
    case 'speech-bubble':
      return <SpeechBubble style={style} className={className} />
  }
}
