import { Container, Typography } from '@material-ui/core'
import React from 'react'
import { currentData } from '../../../data'
import Image from '../image'
import styled from 'styled-components'
import { useWindowWidth } from '../../hooks/window'
export const Intro = () => {
  const windowWidth = useWindowWidth()
  console.log(windowWidth)
  return (
    <div
      style={{
        paddingBottom: 32,
      }}
    >
      <Container
        style={{
          paddingTop: windowWidth > 800 ? 200 : 50,
          paddingBottom: 200,
        }}
        maxWidth={'md'}
      >
        <FlexContainer windowWidth={windowWidth}>
          <ImageCropper
            windowWidth={windowWidth}
            className={'shadow'}
          >
            <Image />
          </ImageCropper>

          <TextBox className={'shadow'} windowWidth={windowWidth}>
            <TextBoxRibbon>
              <Typography
                variant={'caption'}
                style={{ color: 'white', paddingLeft: 16 }}
              >
                {currentData.welcome}
              </Typography>
            </TextBoxRibbon>

            <Typography variant={'body1'} style={{ padding: 16 }}>
              {currentData.intro}
            </Typography>
          </TextBox>
        </FlexContainer>
      </Container>
    </div>
  )
}
interface FlexContainerInterface {
  windowWidth: number
}
const FlexContainer = styled.div<FlexContainerInterface>`
  display: flex;
  /* border: 1px solid black; */
  align-items: ${props =>
    props.windowWidth < 800 ? 'center' : 'flex-end'};

  flex-direction: ${props =>
    props.windowWidth < 800 ? 'column' : 'row'};
`
interface ImageCropperInterface {
  windowWidth: number
}
const ImageCropper = styled.div<ImageCropperInterface>`
  z-index: 5;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  max-width: ${props =>
    props.windowWidth < 800 ? '300px' : '450px'};
  flex: 1;
  margin-bottom: ${props =>
    props.windowWidth < 800 ? '8px' : '0px'};
`
interface TextBoxInterface {
  windowWidth: number
}
const TextBox = styled.div<TextBoxInterface>`
  flex: 1;
  padding-right: 16;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: 32;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  max-width: ${props =>
    props.windowWidth < 800 ? '450px' : '650px'};
  margin-left: ${props => (props.windowWidth < 800 ? '0px' : '8px')};
`

const TextBoxRibbon = styled.div`
  width: 100%;
  height: 48px;
  background-color: ${currentData.palette.primary};
  border-top: 8px solid ${currentData.palette.secondary};
`
