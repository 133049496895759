import { Typography } from '@material-ui/core'
import React from 'react'
import { currentData } from '../../../data'
import styled from 'styled-components'
import { useWindowWidth } from '../../hooks/window'
export const CVHeader = () => {
  const windowWidth = useWindowWidth()
  return (
    <React.Fragment>
      <SectionContainer
        style={{
          backgroundColor: currentData.palette.primary,
        }}
      >
        <TrimHeader />
        <CenteringContainer>
          <TitleContainer>
            <Typography
              align={windowWidth > 800 ? 'center' : 'left'}
              variant={'h4'}
              style={{
                borderBottom: '2px dashed white',
                color: 'white',
                padding: 12,
              }}
            >
              Summary
            </Typography>
          </TitleContainer>
          <SummaryContainer>
            <Typography
              style={{
                color: '#fff',

                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,

                // border: '1px solid white',
              }}
              align={'center'}
            >
              {currentData.cv.summary}
            </Typography>
          </SummaryContainer>
        </CenteringContainer>
      </SectionContainer>
      {/* <TriangleContainer>
        <TriangleLeft />
        <TriangleRight />
      </TriangleContainer> */}
    </React.Fragment>
  )
}

const TrimHeader = styled.div`
  height: 16px;
  width: 100;
  background-color: ${currentData.palette.secondary};
`
const TitleContainer = styled.div`
  max-width: 600px;
`

const SummaryContainer = styled.div`
  max-width: 600px;
  padding-top: 32px;
`

const CenteringContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SectionContainer = styled.div`
  background-color: ${currentData.palette.primary};
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-bottom: 10%;
    background: ${currentData.palette.primary};
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }
`
