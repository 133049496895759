import { createMuiTheme } from '@material-ui/core/styles'
// import 'typeface-playfair-display'
// import 'typeface-raleway'
// import 'typeface-noto-sans'
// import 'typeface-noto-serif'

export const lauraTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#a2de96',
    },
    text: {
      primary: '#1b2121',
      secondary: '#4e8a96',
    },
  },
  typography: {
    fontFamily: 'Raleway',
    h1: {
      fontFamily: 'Playfair Display',
    },
    h2: {
      fontFamily: 'Playfair Display',
    },
    h3: {
      fontFamily: 'Playfair Display',
      fontWeight: 700,
      fontSize: 46,

      fontStyle: 'italic',
    },
    h4: {
      fontFamily: 'Playfair Display',
    },
    h5: {
      fontFamily: 'Playfair Display',
      marginBottom: 16,
    },
    h6: {
      fontFamily: 'Playfair Display',
      marginBottom: 8,
      lineHeight: 1,
    },
    subtitle1: {
      lineHeight: 1.4,
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: 'Playfair Display',
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontFamily: 'Playfair Display',
      fontWeight: 500,
    },
    caption: {
      fontFamily: 'Playfair Display',
      fontWeight: 900,
      fontStyle: 'italic',
      fontSize: 26,
      lineHeight: 1.4,
    },
  },
})

export const emilyTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#a2de96',
    },
    text: {
      primary: '#1b2121',
      secondary: '#4e8a96',
    },
  },
  typography: {
    fontFamily: 'Noto Sans',
    h1: {
      fontFamily: 'Noto Serif',
    },
    h2: {
      fontFamily: 'Noto Serif',
    },
    h3: {
      fontFamily: 'Noto Serif',
      fontWeight: 700,
      fontSize: 46,
      lineHeight: 0.88,
      fontStyle: 'italic',
    },
    h4: {
      fontFamily: 'Noto Serif',
    },
    h5: {
      fontFamily: 'Noto Serif',
      marginBottom: 16,
    },
    h6: {
      fontFamily: 'Noto Serif',
      fontSize: '1rem',
      marginBottom: 8,
      lineHeight: 1,
    },
    subtitle1: {
      lineHeight: 1.4,
      fontSize: 14,
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: 'Noto Serif',
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontFamily: 'Noto Serif',
      fontWeight: 500,
    },
    caption: {
      fontFamily: 'Noto Serif',
      fontWeight: 900,
      fontStyle: 'italic',
      fontSize: 26,
      lineHeight: 1.4,
    },
  },
})
