import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { currentData } from '../../../data'
import { useWindowWidth } from '../../hooks/window'

import { GetIcon } from '../IconGetter'
import './KeySkillsIcon.css'
export const KeySkills = () => {
  const skillsLeft = currentData.cv.skills.slice(
    0,
    +(currentData.cv.skills.length / 2).toFixed(),
  )
  const skillsRight = currentData.cv.skills.slice(
    +(currentData.cv.skills.length / 2).toFixed(),
    currentData.cv.skills.length,
  )
  const windowWidth = useWindowWidth()

  if (windowWidth > 600) {
    return (
      <ColumnContainer>
        <LightColumn>
          {skillsLeft.map((v, i) => {
            return (
              <SkillSquare key={'skill-square-' + i}>
                <GetIcon
                  type={v.icon}
                  className={'key-skills-icon'}
                  style={{
                    minHeight: 64,
                    minWidth: 64,

                    fill: currentData.palette.primary,
                  }}
                />
                <Typography
                  variant={'h5'}
                  align={'center'}
                  style={{ paddingTop: 16 }}
                >
                  {v.name}
                </Typography>
                <Typography
                  align={'center'}
                  style={{ maxWidth: 200 }}
                >
                  {v.description}
                </Typography>
              </SkillSquare>
            )
          })}
        </LightColumn>
        {windowWidth > 800 ? (
          <DarkColumn>
            <Typography
              variant={'h4'}
              align={'center'}
              style={{
                paddingTop: 64,
                color: '#636363',
                borderBottom: '2px dashed #636363 ',
                marginLeft: 16,
                marginRight: 16,

                paddingBottom: 16,
              }}
            >
              Key Skills
            </Typography>
          </DarkColumn>
        ) : null}

        <LightColumn>
          {skillsRight.map((v, i) => {
            return (
              <SkillSquare key={'skill-square-' + i}>
                <GetIcon
                  type={v.icon}
                  className={'key-skills-icon'}
                  style={{
                    fill: currentData.palette.primary,
                    minHeight: 64,
                    minWidth: 64,
                  }}
                />
                <Typography
                  variant={'h5'}
                  align={'center'}
                  style={{ paddingTop: 16 }}
                >
                  {v.name}
                </Typography>
                <Typography
                  align={'center'}
                  style={{ maxWidth: 200 }}
                >
                  {v.description}
                </Typography>
              </SkillSquare>
            )
          })}
        </LightColumn>
      </ColumnContainer>
    )
  } else {
    return currentData.cv.skills.map((v, i) => {
      return (
        <SkillSquare key={'skill-square-' + i}>
          <GetIcon
            type={v.icon}
            className={'key-skills-icon'}
            style={{
              fill: currentData.palette.primary,
              minHeight: 64,
              minWidth: 64,
            }}
          />
          <Typography
            variant={'h5'}
            align={'center'}
            style={{ paddingTop: 16 }}
          >
            {v.name}
          </Typography>
          <Typography align={'center'} style={{ maxWidth: 300 }}>
            {v.description}
          </Typography>
        </SkillSquare>
      )
    })
  }
}

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 64px;
  margin-top: -16px;
  padding-bottom: 128px;
`
const LightColumn = styled.div`
  background-color: white;
  flex: 1;
`
const DarkColumn = styled.div`
  background-color: #e8e9ed;
  flex: 1;
  position: relative;
  margin-top: -16;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-bottom: 30%;
    background: #e8e9ed;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }
`
const SkillSquare = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  transition: all 0.3s;
  margin: 32px;
  max-height: 300px;
`
