import { cvInterface } from './types'

//https://www.flickr.com/photos/127441481@N07/page1
//Yoga, cats, socialite, dog walking, hikes, trying new things
export const lauraData: cvInterface = {
  palette: {
    primary: '#D34F73',
    secondary: '#DBBEA1',
    third: '#3F292B',
    cardColor: '#E1E8E8',
  },
  firstName: 'Laura',
  secondName: 'Donaldson',
  tagline: 'Seeking a role in Social Work',
  city: 'Crewe',
  county: 'Cheshire',
  phoneNumber: '+44 7760 308189',
  email: 'lauraruthdonaldson@hotmail.com',
  welcome: 'Hi,',
  intro:
    'Highly motivated and customer focused person. Experienced in working within customer services, combined with a good understanding of the retail lifestyle. Well presented with a professional manner. Strong work ethic, self-starter and a team player. Enjoys the challenge of dealing with the unexpected. Very safety conscious.',
  cv: {
    summary:
      'Highly motivated and customer focused person. Experienced in working within customer services, combined with a good understanding of the retail lifestyle. Well presented with a professional manner. Strong work ethic, self-starter and a team player. Enjoys the challenge of dealing with the unexpected. Very safety conscious.',
    workExperience: [
      {
        jobTitle: 'Barista',
        companyName: 'Gourmet Coffee Bar',
        location: 'Crewe',
        startDate: new Date('2018-06-01'),
        endDate: new Date(),
        description:
          'Preparing the kiosk before opening times so the shop is ready to receive happy customers, taking in orders at an extremely fast pace whilst making high-standard quality of drinks, as well as ringing up payments. Grinding the coffee beans to the set requirement provided for the company. Following clean as you go routine in addition to keeping track of inventory and new orders. Understanding the importance of Food Hygiene and Safety for Catering (level two) and how to provide the knowledge into  daily tasks.',
      },
      {
        jobTitle: 'Crew Member',
        companyName: 'Dominos',
        location: 'Alsager',
        startDate: new Date('2017-10-01'),
        endDate: new Date('2018-10-01'),
        description:
          'Providing a high standard of customer service during high-volume, fast-paced operations. I also have to communicate a clear and positive agreement with co-workers and management when crosstraining. Along with coordinating an excellent schedule with team members to ensure seamless service. Follow procedures for safe food preparation, assembly and presentation. Deal with customers face to face and over the telephone including difficult situations. Cash handling. Flexible with working hours, including weekend work.   ',
      },
      {
        jobTitle: 'Ambient',
        companyName: 'Asda',
        location: 'Alsager',
        startDate: new Date('2015-06-01'),
        endDate: new Date('2017-09-01'),
        description:
          ' Restocking shelves and maintaining stock levels. Dealing with customers including difficult situations. I had to prioritise my workload and work to tight deadlines. Worked within a team and was flexible, helping out with staff shortages and busier periods. Kept the work area clean and tidy. Helped with other departments during busier periods including till work and cash handling on the tills including cash handling.',
      },
    ],
    education: [
      {
        name: 'Stoke-on-Trent College',
        startDate: new Date('2018-09-10'),
        endDate: new Date('2019-09-10'),
        qualifications: [
          {
            name: 'Makeup Consultancy & Nails Treatments',
            type: 'VTCT',
            grade: 'Level 2',
          },
        ],
      },
      {
        name: 'South Cheshire College',
        startDate: new Date('2018-09-10'),
        endDate: new Date('2019-09-10'),
        qualifications: [
          {
            name: 'Art and Design',
            type: 'Foundation Diploma',
            grade: 'Pass',
          },
        ],
      },
      {
        name: 'Alsager High School',
        startDate: new Date('2013-09-10'),
        endDate: new Date('2018-09-10'),
        qualifications: [
          { name: 'Maths', type: 'GCSE', grade: 'C' },
          { name: 'English', type: 'GCSE', grade: 'C' },
          { name: 'IT', type: 'GCSE', grade: 'C' },
          { name: 'Art', type: 'GCSE', grade: 'C' },
        ],
      },
    ],
    skillsIntro:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dolor velit, vulputate non erat eu, molestie facilisis lectus. Donec consectetur sapien lectus, et luctus augue sagittis sed. Quisque sed vestibulum nibh, sit amet posuere risus. Vestibulum ut metus eu enim sagittis faucibus. Ut accumsan dui nisl, et rhoncus purus euismod vitae. Aliquam pulvinar vulputate velit non tristique. Nullam elementum finibus aliquam. Maecenas sollicitudin tincidunt porta. Sed pellentesque nisi ac viverra iaculis. ',
    skills: [
      {
        name: 'Teaching',
        description:
          'I love helping children reach their full potential through the art of teaching. I have expereince teaching children ages x to y.',
        experience: '3 years',
        icon: 'lecture',
      },
      {
        name: 'Care',
        description:
          'I find caring others very fullfilling. A large part of teaching children is ensuring that their needs are met and they are all happy and safe.',
        experience: '3 years',
        icon: 'care',
      },
      {
        name: 'Logistics',
        description:
          'Some people have described me as an organisatoinal machine! I get huge amounts of satisfaction in creating and organising spaces that brighten peoples days.',
        experience: '3 years',
        icon: 'inventory',
      },
      {
        name: 'Water Sports',
        description:
          'I have professional experience teaching adults and children various water spots. Some of my favorites include kyakaing and paddel boarding.',
        experience: '3 years',
        icon: 'canoe',
      },
      {
        name: 'Covid Aware',
        description:
          'During these difficult times I understand the need to work in bubbles with children to minimise covid exposure and keep everyone safe.',
        experience: '3 years',
        icon: 'mask',
      },
      {
        name: 'Service',
        description:
          'I have great communication skills that I put to work when talking with parents or instructing out on the water.',
        experience: '3 years',
        icon: 'speech-bubble',
      },
    ],
  },
}
