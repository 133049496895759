import { useState, useEffect } from 'react'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(0)
  // getWindowDimensions(),

  useEffect(() => {
    function handleResize() {
      //console.log('handling resize')

      //console.log(window.innerWidth)
      setWindowWidth(window.innerWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowWidth
}
