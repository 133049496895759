import React from 'react'

import SEO from '../components/seo'
import { Container, Paper, ThemeProvider } from '@material-ui/core'

import { Layout } from '../components/layout'
import { currentData, currentTheme } from '../../data'

import { Intro } from '../components/Sections/Intro'
import { Experience } from '../components/Sections/Experience'
import { CVHeader } from '../components/Sections/CVHeader'
import { CVFooter } from '../components/Sections/CVFooter'
import { KeySkills } from '../components/Sections/KeySkills'
import styled from 'styled-components'

const IndexPage = () => {
  console.log(currentData.cv.workExperience)
  return (
    <ThemeProvider theme={currentTheme}>
      <Layout>
        <SEO title="Home" />
        <Intro />
        <Container maxWidth={'md'}>
          <Paper elevation={10}>
            <CVHeader />
            <KeySkills />
            <Experience />
            <CVFooter />
          </Paper>
        </Container>
        <Spacer />
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage

const Spacer = styled.div`
  content: '';
  height: 300px;
`
