import { Container, Hidden, Typography } from '@material-ui/core'
import React, { RefObject } from 'react'
import { currentData } from '../../../data'
import { ExperienceItem } from '../../../data/types'
import { GeneralExperienceBlock } from './GeneralExperienceBlock'

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagic } from '@fortawesome/free-solid-svg-icons'
import { useWindowWidth } from '../../hooks/window'
import styled from 'styled-components'

export const Experience = () => {
  return (
    <div style={{ zIndex: -10 }}>
      <Container
        style={{
          paddingBottom: 32,
        }}
        maxWidth={'md'}
      >
        <TitleContainer>
          <Bar />
          <Typography
            variant={'h3'}
            style={{
              textAlign: 'center',
              color: currentData.palette.primary,
              padding: 16,
            }}
          >
            Career and Education
          </Typography>
          <Bar />
        </TitleContainer>
        <Typography
          variant={'body1'}
          style={{ textAlign: 'center', paddingBottom: 64 }}
        >
          Here is a collection of my work and education experience.
        </Typography>
        {/* <FontAwesomeIcon icon={faMagic} /> */}
        {/* <Hidden only={['sm', 'xs']}>
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <ArrowDropUpIcon
              style={{
                color: currentData.palette.primary,
                width: 64,
                height: 64,
                position: 'relative',
                bottom: 30,
              }}
            />
          </div>
        </Hidden> */}
        <ExperienceFactory />
      </Container>
    </div>
  )
}

function ExperienceFactory() {
  const screen = useWindowWidth()
  const exprienceList: ExperienceItem[] = []
  const exprienceListLeft: ExperienceItem[] = []
  const exprienceListRight: ExperienceItem[] = []
  exprienceList.push(...currentData.cv.workExperience)
  exprienceList.push(...currentData.cv.education)
  const sortedActivities = exprienceList
    .slice()
    .sort(
      (a: ExperienceItem, b: ExperienceItem) => b.endDate - a.endDate,
    )
  const refArray: RefObject<HTMLDivElement>[] = []
  const columnRef = React.useRef<HTMLDivElement>(null)

  for (let i = 0; i < sortedActivities.length; i++) {
    refArray.push(React.useRef<HTMLDivElement>(null))
  }

  sortedActivities.forEach((v, i) => {
    if (i % 2) {
      exprienceListRight.push(v)
    } else {
      exprienceListLeft.push(v)
    }
  })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'stretch',
      }}
      ref={columnRef}
    >
      {screen >= 960 ? (
        <React.Fragment>
          <div style={{ flex: 1 }}>
            {exprienceListLeft.map((v, i) => {
              return (
                <GeneralExperienceBlock
                  key={'gebl-' + i}
                  experienceItem={v}
                  position={'LEFT'}
                  ref={refArray[i * 2]}
                />
              )
            })}
          </div>
          {/* <div
            style={{
              flex: 0.01,
              backgroundColor: currentData.palette.primary,
              height: '100%',
              content: '',
            }}
          >
            &nbsp;
          </div> */}
          <div
            style={{
              flex: 1,
              paddingTop: 32,
            }}
          >
            {exprienceListRight.map((v, i) => {
              return (
                <GeneralExperienceBlock
                  key={'gebr-' + i}
                  experienceItem={v}
                  position={'RIGHT'}
                  ref={refArray[(i + 1) * 2 - 1]}
                />
              )
            })}
          </div>
        </React.Fragment>
      ) : (
        <div style={{ flex: 1 }}>
          {sortedActivities.map((v, i) => {
            return (
              <GeneralExperienceBlock
                key={'gebc-' + i}
                experienceItem={v}
                position={'CENTER'}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const Bar = styled.div`
  height: 4px;
  width: 100%;
  flex: 1;
  background-color: ${currentData.palette.primary};
`

const TitleContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`
