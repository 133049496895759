import { Typography } from '@material-ui/core'
import React, { forwardRef } from 'react'
import { currentData } from '../../../data'
import {
  Education,
  ExperienceItem,
  WorkExperience,
} from '../../../data/types'
import { useWindowWidth } from '../../hooks/window'

interface GeneralExperienceBlockProps {
  position: 'LEFT' | 'RIGHT' | 'CENTER'
  experienceItem: ExperienceItem
  ref: any
}

export const GeneralExperienceBlock = React.forwardRef<
  HTMLDivElement,
  GeneralExperienceBlockProps
>((props, ref) => {
  const { position, experienceItem } = props
  const windowWidth = useWindowWidth()
  const paddingBig = 20
  const paddingSmall = 10
  const cardPadding = windowWidth < 560 ? 16 : 8
  const borderThickness = 2

  let paddingLeft = windowWidth < 560 ? 0 : paddingSmall
  let paddingRight = windowWidth < 560 ? 0 : paddingSmall

  switch (position) {
    case 'LEFT':
      paddingLeft = paddingSmall
      paddingRight = paddingBig
      break
    case 'RIGHT':
      paddingRight = paddingSmall
      paddingLeft = paddingBig
      break
  }

  return (
    <div ref={ref} style={{ paddingBottom: 32 }}>
      <Header
        marginLeft={paddingLeft}
        marginRight={paddingRight}
        cardPadding={cardPadding}
        experienceItem={experienceItem}
      />
      <Ribbon experienceItem={experienceItem} position={position} />
      <Footer
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        cardPadding={cardPadding}
        experienceItem={experienceItem}
      />
    </div>
  )
})

interface HeaderProps {
  marginLeft: number
  marginRight: number
  cardPadding: number
  experienceItem: ExperienceItem
}
function Header({
  marginLeft,
  marginRight,
  cardPadding,
  experienceItem,
}: HeaderProps) {
  const education = experienceItem as Education
  const workExperience = experienceItem as WorkExperience
  const windowWidth = useWindowWidth()
  return (
    <div
      style={{
        marginLeft: marginLeft,
        marginRight: marginRight,
        height: windowWidth < 560 ? 64 : 48,

        overflow: 'hidden',
      }}
    >
      <div
        style={{
          backgroundColor: currentData.palette.cardColor,
          padding: cardPadding,
        }}
      >
        <Typography variant={'h6'} style={{ marginTop: 6 }}>
          {workExperience.jobTitle && workExperience.companyName
            ? `${workExperience.jobTitle} - ${workExperience.companyName}`
            : null}
          {education.name ? `${education.name}` : null}
        </Typography>
      </div>
    </div>
  )
}

interface RibbonProps {
  position: 'LEFT' | 'RIGHT' | 'CENTER'
  experienceItem: ExperienceItem
}

function Ribbon({ position, experienceItem }: RibbonProps) {
  const education = experienceItem as Education
  const workExperience = experienceItem as WorkExperience
  const windowWidth = useWindowWidth()
  let flexDirection = 'row'
  let textAlignLocation = 'left'
  let textAlignDate = 'right'

  switch (position) {
    case 'RIGHT':
      flexDirection = 'row-reverse'
      textAlignLocation = 'right'
      textAlignDate = 'left'
      break
  }

  return (
    <div
      style={{
        backgroundColor: currentData.palette.primary,
        position: 'relative',
        display: 'flex',
        flexDirection,
        paddingLeft: 16,
        paddingRight: 16,
        color: 'white',
        fontWeight: 9,
        justifyItems: 'flex-end',
        height: windowWidth < 560 ? 44 : 22,
      }}
    >
      {workExperience.location ? (
        <Typography
          variant={'body1'}
          style={{ flex: 1, textAlign: textAlignLocation }}
        >
          <b>{workExperience.location}</b>
        </Typography>
      ) : null}

      <Typography
        variant={'body1'}
        style={{ flex: 1, textAlign: textAlignDate }}
      >
        {experienceItem.startDate.getFullYear()} -{' '}
        {experienceItem.endDate.getFullYear()}
      </Typography>
    </div>
  )
}

interface FooterProps {
  paddingLeft: number
  paddingRight: number
  cardPadding: number
  experienceItem: ExperienceItem
}
function Footer({
  paddingLeft,
  paddingRight,
  cardPadding,
  experienceItem,
}: FooterProps) {
  const education = experienceItem as Education
  const workExperience = experienceItem as WorkExperience
  const windowWidth = useWindowWidth()
  return (
    <div
      style={{
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
      }}
    >
      <div
        style={{
          backgroundColor: currentData.palette.cardColor,
          padding: cardPadding,
        }}
      >
        {workExperience.description ? (
          <Typography
            variant={'body1'}
            style={{
              paddingLeft: windowWidth < 560 ? 0 : 16,
              marginLeft: windowWidth < 560 ? 0 : 16,
              marginTop: windowWidth < 560 ? 0 : 16,
              marginBottom: 16,
              borderLeft:
                windowWidth < 560
                  ? ''
                  : '2px solid ' + currentData.palette.primary,
            }}
          >
            {workExperience.description}
          </Typography>
        ) : null}

        {education.qualifications ? (
          <div
            style={{
              marginTop: 8,
              borderLeft: '2px solid ' + currentData.palette.primary,
              paddingLeft: 8,
              marginLeft: 8,
              marginBottom: 16,
            }}
          >
            {education.qualifications.map((v, i) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
                key={'qualification' + i}
              >
                <Typography style={{ flex: 3 }}>{v.name}</Typography>
                <Typography style={{ flex: 1 }}>{v.type}</Typography>
                <Typography style={{ flex: 1 }}>{v.grade}</Typography>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}
